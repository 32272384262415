import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.main`
  max-width: 1200px;
  margin: ${({ tight }) => !tight ? '50px auto' : '0 auto'};
`;

function Container({ children, tight }) {
  return (
    <Wrapper tight={tight}>
      {children}
    </Wrapper>
  );
}

export default Container;
