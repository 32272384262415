const BANDSINTOWN_API_KEY = 'd323be45ea55b1ed1c6751525a2b742b';

let events = undefined;

export async function getEvents() {
    if (typeof events === 'undefined') {
      const result = await fetch(`https://rest.bandsintown.com/artists/albinleemeldau/events/?app_id=${BANDSINTOWN_API_KEY}`)
        .then(res => res.json());
      console.log(result);
      events = result;
    }
    return events;
  }
