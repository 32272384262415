import { Link, useNavigate } from '@gatsbyjs/reach-router';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import YouTubeEmbed from './YouTubeEmbed';

const Backdrop = styled(Link)`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.4);
  text-align: center;
  padding: 50px;
  overflow: auto;
  cursor: default;
`;

const CloseLink = styled(Link)`
  position: absolute;
  top: -20px;
  right: -20px;
  text-decoration: none;
  font-size: 50px;
  line-height: .7;
  background-color: #000;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  color: #fff;

  &:hover {
    background-color: #ff0000;
  }
`;

const ModalContainer = styled.div`
  display: inline-block;
  position: relative;
  background-color: #fff;
  padding: 30px;
  box-shadow: 0 0 20px #000;
`;

function Modal({ id, path, closeLocation }) {
  const navigate = useNavigate();

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key !== 'Escape') return;
      navigate(closeLocation);
    };

    document.body.classList.add('modal-open');
    window.addEventListener('keyup', handleEscape);

    return () => {
      document.body.classList.remove('modal-open')
      window.removeEventListener('keyup', handleEscape);
    };
  }, [closeLocation, navigate]);

  return (
    <Backdrop to={closeLocation}>
      <ModalContainer>
        {closeLocation && <CloseLink to={closeLocation}>&times;</CloseLink>}
        {path.includes('videos/') && (
          <YouTubeEmbed id={id} />
        )}
      </ModalContainer>
    </Backdrop>
  );
}

export default Modal;
