import { Link } from "@gatsbyjs/reach-router";
import React from 'react';
import styled from 'styled-components';

const Overlay = styled.span`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,.85);
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  transition: all 250ms;
  opacity: 0;

  a {
    color: #fff;
    text-decoration: none;
    font-size: 50pt;
    margin: 10px;
    opacity: .5;

    &:hover {
      opacity: 1;
    }
  }
`;

const Wrapper = styled(Link)`
  display: block;
  position: relative;
  background-color: #DB7714;
  width: 100%;
  padding: 0;
  margin: 0;
  text-decoration: none;

  &:hover ${Overlay} {
    opacity: 1;
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;

const Title = styled.span`
  display: block;
  color: #fff;
  padding: 15px;
  line-height: 1;
`;

function Card({ image, title, location }) {
  const content = (
    <>
      {image && <Image src={image} alt={title} />}
      {title && <Title>{title.replace('&amp;', '&').replace('&quot;', '"')}</Title>}
    </>
  );

  if (Array.isArray(location)) {
    return (
      <Wrapper as="div" to="">
        {content}
        <Overlay>
          {location.map((item, index) => item.url && <a key={`service${title}${index}`} href={item.url}><i className={item.icon} /></a>)}
        </Overlay>
      </Wrapper>
    )
  }

  if (location && location.startsWith('http')) {
    return (
      <Wrapper as="a" href={location} target="_blank">
        {content}
      </Wrapper>
    )
  }

  return (
    <Wrapper to={location}>
      {content}
    </Wrapper>
  );
}

export default Card;
