import { Link } from '@gatsbyjs/reach-router';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Card from '../Components/Card';
import Container from '../Components/Container';
import Loader from '../Components/Loader';
import { getData } from '../Sources/Music';

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;;
`;

const FilterElement = styled.nav`
  background-color: #DB7714;
  padding: 20px;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
  }

  li {
    display: inline-block;
  }

  li + li {
    &::before {
      padding: 0 20px;
      content: '|';
      color: #fff;
    }
  }

  a {
    text-decoration: none;
    color: #fff;
    text-transform: uppercase;

    &[aria-current] {
      text-decoration: underline;
    }

    &:hover {
      text-decoration: underline;
    }
  }
`;

function Music({filter}) {
  const [items, setItems] = useState([]);

  const Filter = () => {
    return (
      <FilterElement>
        <Container tight>
          <ul>
            <li><Link to="/music">All releases</Link></li>
            <li><Link to="/music/album">Albums</Link></li>
            <li><Link to="/music/single">Singles</Link></li>
            <li><Link to="/music/ep">EPs</Link></li>
          </ul>
        </Container>
      </FilterElement>
    )
  };

  useEffect(() => {
    async function loadMusic() {
      const data = await getData(filter);
      setItems(data);
    }
    loadMusic();
  }, [filter]);

  return (
    <>
      {items.length && <Filter />}
      <Container>
        {!items.length && <Loader />}
        <Grid>
          {items.map((item, index) =>
            <Card
              key={`album-${index}`}
              image={item.artwork}
              title={item.name}
              location={[
                {
                  icon: 'ri-spotify-fill',
                  url: item.spotify,
                },
                {
                  icon: 'ri-apple-fill',
                  url: item.appleMusic,
                },
              ]}
            />
          )}
        </Grid>
      </Container>
    </>
  )
}

export default Music;
