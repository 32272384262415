import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Container from '../Components/Container';
import Loader from '../Components/Loader';
import { getVideos } from '../Sources/YouTube';
import Card from '../Components/Card';

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;;
`;

function Videos() {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    async function loadVideos() {
      const videos = await getVideos();
      setVideos(videos.filter((video) => video.id.videoId));
    }
    loadVideos();
  }, []);

  return (
    <Container>
      {!videos.length && <Loader />}
      <Grid>
        {videos.map((video) =>
          <Card
            key={video.id.videoId}
            image={video.snippet.thumbnails.high.url}
            title={video.snippet.title}
            location={`/videos/${video.id.videoId}`}
          />
        )}
      </Grid>
    </Container>
  )
}

export default Videos;
