import { Router } from "@gatsbyjs/reach-router";
import MainMenu from './Components/MainMenu';
import Home from './Routes/Home';
import Music from './Routes/Music';
import Tour from './Routes/Tour';
import Videos from './Routes/Videos';

import Modal from './Components/Modal';

/*
  NAV STRUCUTRE (parsed from)
  ---------------------------
  Home (Facebook feed)
  Tour (Bandsintown)
  Music (Spotify)
  Videos (YouTube or Vimeo)
  Store

  Social links:
  Facebook, Twitter, Instagram, YouTube, Soundcloud, Spotify, Apple Music)
*/

function App() {
  return (
    <>
      <MainMenu />
      <Router>
        <Home path="/" />
        <Tour path="/tour" />
        <Music path="/music" />
        <Music path="/music/:filter" />
        <Videos path="/videos/*" />
      </Router>

      <Router primary={false}>
        <Modal path="/videos/:id" closeLocation="/videos" />
      </Router>
    </>
  );
}

export default App;
