import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('/images/cover.jpg');
  background-size: cover;
  background-position: center;
`;

function Hero() {
  return (
    <Wrapper />
  );
}

export default Hero;
