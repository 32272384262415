import React from 'react';
import styled from 'styled-components';

const YouTubeContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  width: 60vw;
  height: 0;
  overflow: hidden;

  iframe, object, embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

function YouTube({ id }) {
  return (
    <YouTubeContainer>
      <iframe
        width="560"
        height="315"
        src={`https://www.youtube.com/embed/${id}`}
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen>
      </iframe>
    </YouTubeContainer>
  )
}

export default YouTube;
