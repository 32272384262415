const YOUTUBE_API_KEY = 'AIzaSyB1kXh7WVUSfHb1pGqJLYSCiEhJN5s_NM4';
const YOUTUBE_CHANNEL_ID = 'UC3RYwOhOMltWwP2z5nFeWiw';

let videos = [];

export async function getVideos() {
  if (!videos.length) {
    const result = await fetch(`https://www.googleapis.com/youtube/v3/search?key=${YOUTUBE_API_KEY}&channelId=${YOUTUBE_CHANNEL_ID}&part=snippet,id&order=date&maxResults=20`)
      .then(res => res.json());
    videos = result.items;
  }
  return videos;
}
