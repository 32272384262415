let music = null;

export async function getData(type) {
  if (!music) {
    music = await fetch('/data/music.json').then(res => res.json());
    music = music.sort((a, b) => new Date(b.releaseDate) - new Date(a.releaseDate));
  }

  if (['album', 'ep', 'single'].includes(type)) {
    return music.filter(m => m.type === type)
  }

  return music;
}
