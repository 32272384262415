import React, { useEffect } from 'react';
import Footer from '../Components/Footer';
import Hero from '../Components/Hero';

function Home() {
  useEffect(() => {
    document.body.classList.add('home');
    return () => document.body.classList.remove('home');
  });

  return (
    <>
      <Hero />
      <Footer />
    </>
  )
}

export default Home;
