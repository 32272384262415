import React from 'react';
import ReactDOM from 'react-dom';
import { createGlobalStyle } from 'styled-components';
import { Normalize } from 'styled-normalize';
import App from './App';
import reportWebVitals from './reportWebVitals';

const GlobalStyle = createGlobalStyle`
  @font-face {
      font-family: 'Sporting Grotesque_Regular';
      src:    url('/fonts/regular/Sporting_Grotesque-Regular_web.eot');
      src:    url('/fonts/regular/Sporting_Grotesque-Regular_web.eot?#iefix') format('embedded-opentype'),
              url('/fonts/regular/Sporting_Grotesque-Regular_web.woff') format('woff'),
              url('/fonts/regular/Sporting_Grotesque-Regular_web.woff2') format('woff2'),
              url('/fonts/regular/Sporting_Grotesque-Regular_web.ttf') format('truetype'),
              url('/fonts/regular/Sporting_Grotesque-Regular_web.svg#svgFontName') format('svg');
      font-weight: normal;
      font-style: normal;
  }

  @font-face {
      font-family: 'Sporting Grotesque_Bold';
      src:    url('/fonts/bold/Sporting_Grotesque-Bold_web.eot');
      src:    url('/fonts/bold/Sporting_Grotesque-Bold_web.eot?#iefix') format('embedded-opentype'),
              url('/fonts/bold/Sporting_Grotesque-Bold_web.woff') format('woff'),
              url('/fonts/bold/Sporting_Grotesque-Bold_web.woff2') format('woff2'),
              url('/fonts/bold/Sporting_Grotesque-Bold_web.ttf') format('truetype'),
              url('/fonts/bold/Sporting_Grotesque-Bold_web.svg#svgFontName') format('svg');
      font-weight: normal;
      font-style: normal;
  }

  * {
    box-sizing: border-box;
  }

  body {
    font-family: Sporting Grotesque_Regular, sans-serif;
    background-color: #f1f1f1;
  }

  body.modal-open {
    overflow: hidden;
    height: 100%;
  }
`;

ReactDOM.render(
  <React.StrictMode>
    <Normalize />
    <GlobalStyle />
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
