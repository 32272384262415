import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Container from '../Components/Container';
import Loader from '../Components/Loader';
import { getEvents } from '../Sources/Tour';

const TourStop = styled.a`
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px 30px;

  & + & {
    margin-top: 20px;
  }
`;

const TourDate = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  border-right: 1px solid #d3d3d3;
  padding-right: 30px;
  color: #DB7714;
`;

const Year = styled.span`
`;

const Day = styled.span`
  font-size: 35px;
`;

const Month = styled.span`
  font-size: 20px;
`;

const Venue = styled.div`
  padding-left: 30px;
`;

const VenueLocation = styled.div`
  font-size: 40px;
`;

const Ticket = styled.a`
  display: inline-block;
  border: 2px solid #000;
  border-radius: 10px;
  text-decoration: none;
  padding: 10px 20px 8px 20px;
  color: #000;
  margin-left: auto;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;

  &:hover {
    background-color: #cecece;
  }
`;

const NoEvents = styled.div`
  text-align: center;
`;

function Tour() {
  const [events, setEvents] = useState(undefined);

  useEffect(() => {
    async function loadEvents() {
      const data = await getEvents();
      setEvents(data);
    }
    loadEvents();
  }, []);

  const renderEvents = () => {
    if (typeof events === 'undefined') {
      return <Loader />;
    }

    if (events.length === 0) {
      return (
        <NoEvents>Sorry, there is no upcoming events, please come back later</NoEvents>
      );
    }

    console.log(events);

    return events.map((event) => {
      return (
        <TourStop>
          <TourDate>
            <Year>{new Date(event.starts_at).getFullYear()}</Year>
            <Day>{new Date(event.starts_at).getDate() > 9 ? new Date(event.starts_at).getDate() : `0${new Date(event.starts_at).getDate()}`}</Day>
            <Month>{new Date(event.starts_at).toLocaleString('default', { month: 'short' }).toUpperCase()}</Month>
          </TourDate>
          <Venue>
            <VenueLocation>{event.venue.location}</VenueLocation>
            <div>{event.venue.name}</div>
          </Venue>
          <Ticket href={event.url}>More info</Ticket>
        </TourStop>
      );
    });

    // return (
    //   <Table cellPadding={0} cellSpacing={0}>
    //     {events.map((event) =>
    //       <tr>
    //         <td>{new Date(event.starts_at).toLocaleDateString()}</td>
    //         <td>{event.venue.name}</td>
    //         <td>{event.venue.location}</td>
    //         <td><a href={event.url}>More info</a></td>
    //       </tr>
    //     )}
    //   </Table>
    // )
  };

  return (
    <Container>
      {renderEvents()}
    </Container>
  )
}

export default Tour;
