import React from 'react';
import styled, { keyframes } from 'styled-components';

const size = '20px';
const duration = '0.7s';

const move = keyframes`
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  70% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: calc(${size} * 3);
  height: calc(${size} * 3);
  margin: 0 auto;
`;

const Item = styled.div`
  position: absolute;
  height: ${size};
  width: ${size};
  background: #000;
  animation: ${move} ${duration} linear infinite;

  &:nth-of-type(1) {
      top: 0;
      left: 0;
      animation-delay: 0s;
  }

  &:nth-of-type(2) {
      top: 0;
      left: ${size};
      animation-delay: calc(-${duration} / 8);
  }

  &:nth-of-type(3) {
      top: 0;
      left: calc(${size} * 2);
      animation-delay: calc(-2 * ${duration} / 8);
  }

  &:nth-of-type(4) {
      top: ${size};
      left: calc(${size} * 2);
      animation-delay: calc(-3 * ${duration} / 8);
  }

  &:nth-of-type(5) {
      top: calc(${size} * 2);
      left: calc(${size} * 2);
      animation-delay: calc(-4 * ${duration} / 8);
  }

  &:nth-of-type(6) {
      top: calc(${size} * 2);
      left: ${size};
      animation-delay: calc(-5 * ${duration} / 8);
  }

  &:nth-of-type(7) {
      top: calc(${size} * 2);
      left: 0;
      animation-delay: calc(-6 * ${duration} / 8);
  }

  &:nth-of-type(8) {
      top: ${size};
      left: 0;
      animation-delay: calc(-7 * ${duration} / 8);
  }
`;

function Loader({ done }) {
  if (done) {
    return null;
  }

  return (
    <Wrapper>
      <Item />
      <Item />
      <Item />
      <Item />
      <Item />
      <Item />
      <Item />
      <Item />
    </Wrapper>
  );
}

export default Loader;
