import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.footer`
  background-color: #fff;
  text-align: center;
  padding: 15px;
  font-size: 20pt;
  position: fixed;
  bottom: 0;
  width: 100%;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    display: inline-block;
    vertical-align: middle;

    & + li {
      margin-left: 15px;
    }
  }

  a {
    text-decoration: none;
    color: #000;

    &:hover {
      color: #DB7714;
    }
  }
`;

function Footer() {
  return (
    <Wrapper>
      <ul>
        <li><a href="https://www.facebook.com/Albinleemeldau/"><i className="ri-facebook-circle-fill"></i></a></li>
        <li><a href="https://twitter.com/AlbinLeeMeldau"><i className="ri-twitter-fill"></i></a></li>
        <li><a href="https://www.instagram.com/albinleemeldau/"><i className="ri-instagram-fill"></i></a></li>
        <li><a href="https://www.youtube.com/channel/UC3RYwOhOMltWwP2z5nFeWiw"><i className="ri-youtube-fill"></i></a></li>
        <li><a href="https://soundcloud.com/albinleemeldau"><i className="ri-soundcloud-line"></i></a></li>
        <li><a href="https://albinleemeldau.lnk.to/musicWe"><i className="ri-spotify-fill"></i></a></li>
        <li><a href="https://albinleemeldau.lnk.to/musicWe"><i className="ri-apple-fill"></i></a></li>
      </ul>
    </Wrapper>
  );
}

export default Footer;
