import { Link } from "@gatsbyjs/reach-router";
import React from 'react';
import styled from 'styled-components';

const Header = styled.header`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  padding: 20px;
  background: #fff;

  body:not(.home) & {
    background-color: #fff;
  }
`;

const Logo = styled.a`
  display: block;
  width: 300px;

  img {
    width: 100%;
  }
`;

const Nav = styled.nav`
  margin-left: auto;
  margin-top: 5px;

  ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
  }
`;

const NavItem = styled(Link).attrs(() => ({
  getProps: ({ href, isCurrent ,isPartiallyCurrent }) => {
    if ((href === '/' && isCurrent) || (href !== '/' && isPartiallyCurrent)) {
      return { 'data-active': 'true' };
    }
  }
}))`
  display: block;
  padding: 14px 10px;
  color: #000000;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: bold;

  &[data-active] {
    color: #DB7714;
  }

  &:hover {
    text-decoration: underline;
  }
`;

function MainMenu() {
  return (
    <Header>
      <Logo><img src="/images/logo.png" alt="Albin Lee Meldau" /></Logo>
      <Nav>
        <ul>
          <li><NavItem to="/">Home</NavItem></li>
          <li><NavItem to="/tour">Tour</NavItem></li>
          <li><NavItem to="/music">Music</NavItem></li>
          <li><NavItem to="/videos">Videos</NavItem></li>
          <li><NavItem as="a" href="https://merchworld.com/collections/albin-lee-meldau">Merch</NavItem></li>
        </ul>
      </Nav>
    </Header>
  );
}

export default MainMenu;
